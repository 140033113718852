<template>
    
    <vue-tel-input
      v-model="input_value"
      v-bind="bindProps"
      @validate="checkValid"
    ></vue-tel-input>

</template>
<script>
import { VueTelInput } from "vue-tel-input";
export default {
  components: { VueTelInput },
  props: ["value"],
  data() {
    return {
      bindProps: {
        mode: "international",
        disabled: false,
        disabledFormatting: false,
        placeholder: "",
        required: false,
        enabledCountryCode: true,
        enabledFlags: false,
        preferredCountries: ['SE','DK','NO','FI','GB','US'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "off",
        name: this.$t('NUMBER'),
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      },
      input_value: this.value
    };
  },
  methods: {
    checkValid: function(obj) {
      if (obj.isValid) {
        let number = obj.number.international.replace(/\s/g,'');
        let retVal = {
          value: number,
          data: {
            value: number
          }
        };
        this.$emit("changed", retVal);
        this.$emit("valid", true);
      } else {
        this.$emit("valid", false);
      }
    },
    formatter(value) {
      return value.toLowerCase();
    },
  },
};
</script>
<style></style>
